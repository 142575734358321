// Note: Environment variables not loaded from the .env files will not be inlined by Webpack
// For example Cloudflare Pages env variables can't be used in this file
export const IS_PROD = process.env.NODE_ENV === 'production';

export const CURRENT_YEAR = new Date().getFullYear();

export const WORKSPACE = process.env.WORKSPACE;

export const API_ENDPOINT = process.env.API_ENDPOINT;

export const FILES_HOST_PROTOCOL = process.env.FILES_HOST_PROTOCOL;
export const FILES_DOMAIN_NAME = process.env.FILES_DOMAIN_NAME;

export const ADS_ENABLED = process.env.ADS_ENABLED === 'true';
export const AD_URL = process.env.AD_URL;
export const AD_OPEN_INTERVAL = Number(process.env.AD_OPEN_INTERVAL);
export const AD_PWA_OPEN_INTERVAL = Number(process.env.AD_PWA_OPEN_INTERVAL);
