import React from 'react';
import ReactDOM from 'react-dom';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { isBrowser } from 'lib/devices';
import { classNames } from 'lib/helpers';

const modalSizes = {
  sm: 'sm:max-w-sm',
  md: 'sm:max-w-md',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  '4xl': 'sm:max-w-4xl',
  '5xl': 'sm:max-w-5xl',
};

export default function Modal({
  open = false,
  setOpen,
  size = 'md',
  closeButton = true,
  hideCloseOnMobile = false,
  closeOnOverlayClick = true,
  children,
}) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const textAlign = isRTL ? 'text-right' : 'text-left';

  return isBrowser
    ? ReactDOM.createPortal(
        <Transition show={open} className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* <!--
            Background overlay, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100"
              To: "opacity-0"
          --> */}
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div
                className="absolute inset-0 bg-gray-500 opacity-75"
                onClick={closeOnOverlayClick ? () => setOpen(false) : undefined}
                role="presentation"
              ></div>
            </Transition.Child>

            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            {/* <!--
            Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          --> */}
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className={classNames(
                'inline-block align-bottom bg-white rounded-lg pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full px-6',
                modalSizes[size],
                textAlign
              )}
              role="dialog"
              aria-modal="true"
            >
              {closeButton && (
                <div
                  className={classNames(
                    'absolute top-0 right-0 pt-4 pr-4',
                    hideCloseOnMobile ? 'hidden sm:block' : ''
                  )}
                >
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">{t('common:close')}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}

              {children}
            </Transition.Child>
          </div>
        </Transition>,
        document.getElementById('modals')
      )
    : null;
}
