import React from 'react';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from 'react-share';

import { trackShare } from 'lib/analytics';
import Modal from 'components/Modal';

export default function ShareModal({ shareUrl, title, ...props }) {
  return (
    <Modal closeButton={true} closeOnOverlayClick={true} {...props}>
      <div className="pt-8 pb-5">
        <div className="grid grid-cols-4 sm:grid-cols-8 gap-3 text-center">
          <div>
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="cursor-pointer"
              onClick={() => trackShare('facebook')}
            >
              <FacebookIcon size={40} round />
            </FacebookShareButton>
          </div>

          <div>
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="cursor-pointer"
              onClick={() => trackShare('twitter')}
            >
              <TwitterIcon size={40} round />
            </TwitterShareButton>
          </div>

          <div>
            <TelegramShareButton
              url={shareUrl}
              title={title}
              className="cursor-pointer"
              onClick={() => trackShare('telegram')}
            >
              <TelegramIcon size={40} round />
            </TelegramShareButton>
          </div>

          <div>
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className="cursor-pointer"
              onClick={() => trackShare('whatsapp')}
            >
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
          </div>

          <div>
            <LinkedinShareButton
              url={shareUrl}
              className="cursor-pointer"
              onClick={() => trackShare('linkedin')}
            >
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
          </div>

          <div>
            <PinterestShareButton
              url={shareUrl}
              // media={}
              className="cursor-pointer"
              onClick={() => trackShare('pinterest')}
            >
              <PinterestIcon size={40} round />
            </PinterestShareButton>
          </div>

          <div>
            <VKShareButton
              url={shareUrl}
              // image={}
              className="cursor-pointer"
              onClick={() => trackShare('vk')}
            >
              <VKIcon size={40} round />
            </VKShareButton>
          </div>

          <div>
            <OKShareButton
              url={shareUrl}
              // image={}
              className="cursor-pointer"
              onClick={() => trackShare('ok')}
            >
              <OKIcon size={40} round />
            </OKShareButton>
          </div>

          <div>
            <RedditShareButton
              url={shareUrl}
              title={title}
              windowWidth={660}
              windowHeight={460}
              className="cursor-pointer"
              onClick={() => trackShare('reddit')}
            >
              <RedditIcon size={40} round />
            </RedditShareButton>
          </div>

          <div>
            <TumblrShareButton
              url={shareUrl}
              title={title}
              className="cursor-pointer"
              onClick={() => trackShare('tubmlr')}
            >
              <TumblrIcon size={40} round />
            </TumblrShareButton>
          </div>

          <div>
            <LivejournalShareButton
              url={shareUrl}
              title={title}
              description={shareUrl}
              className="cursor-pointer"
              onClick={() => trackShare('livejournal')}
            >
              <LivejournalIcon size={40} round />
            </LivejournalShareButton>
          </div>

          <div>
            <MailruShareButton
              url={shareUrl}
              title={title}
              className="cursor-pointer"
              onClick={() => trackShare('mail.ru')}
            >
              <MailruIcon size={40} round />
            </MailruShareButton>
          </div>

          <div>
            <EmailShareButton
              url={shareUrl}
              subject={title}
              body={shareUrl}
              className="cursor-pointer"
              onClick={() => trackShare('email')}
            >
              <EmailIcon size={40} round />
            </EmailShareButton>
          </div>
          <div>
            <ViberShareButton
              url={shareUrl}
              title={title}
              className="cursor-pointer"
              onClick={() => trackShare('viber')}
            >
              <ViberIcon size={40} round />
            </ViberShareButton>
          </div>

          <div>
            <WorkplaceShareButton
              url={shareUrl}
              quote={title}
              className="cursor-pointer"
              onClick={() => trackShare('workplace')}
            >
              <WorkplaceIcon size={40} round />
            </WorkplaceShareButton>
          </div>

          <div>
            <LineShareButton
              url={shareUrl}
              title={title}
              className="cursor-pointer"
              onClick={() => trackShare('line')}
            >
              <LineIcon size={40} round />
            </LineShareButton>
          </div>

          <div>
            <WeiboShareButton
              url={shareUrl}
              title={title}
              // image={}
              className="cursor-pointer"
              onClick={() => trackShare('weibo')}
            >
              <WeiboIcon size={40} round />
            </WeiboShareButton>
          </div>

          <div>
            <PocketShareButton
              url={shareUrl}
              title={title}
              className="cursor-pointer"
              onClick={() => trackShare('pocket')}
            >
              <PocketIcon size={40} round />
            </PocketShareButton>
          </div>

          <div>
            <InstapaperShareButton
              url={shareUrl}
              title={title}
              className="cursor-pointer"
              onClick={() => trackShare('instapaper')}
            >
              <InstapaperIcon size={40} round />
            </InstapaperShareButton>
          </div>

          <div>
            <HatenaShareButton
              url={shareUrl}
              title={title}
              windowWidth={660}
              windowHeight={460}
              className="cursor-pointer"
              onClick={() => trackShare('hatena')}
            >
              <HatenaIcon size={40} round />
            </HatenaShareButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}
