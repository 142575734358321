import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link as I18nLink } from 'gatsby-plugin-react-i18next';

export default function Link({
  href,
  i18n = true,
  activeClassName,
  partiallyActive,
  ...props
}) {
  if (href.startsWith('/')) {
    const Link = i18n ? I18nLink : GatsbyLink;

    return (
      <Link
        to={href}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...props}
      />
    );
  }

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a href={href} {...props} />;
}
