import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { isBrowser } from 'lib/devices';
import { useToasts } from 'components/Toast';
import Button from 'components/Button';

export default function useAppInstall() {
  const { t } = useTranslation();
  const { addToast, removeToast } = useToasts();

  React.useEffect(() => {
    if (isBrowser) {
      const handler = () => {
        const toastId = addToast({
          title: t('common:pwa.title'),
          content: (
            <>
              <p>{t('common:pwa.desc')}</p>
              <Button
                className="mt-4"
                onClick={() => {
                  window.dispatchEvent(new Event('stv-pwa-prompt'));
                  removeToast(toastId);
                }}
              >
                {t('common:pwa.title')}
              </Button>
            </>
          ),
        });
      };

      const outcomeHandler = (event) => {
        const accepted = event?.detail.outcome === 'accepted';

        if (accepted) {
          addToast({
            title: t('common:pwa.accepted_title'),
            content: t('common:pwa.accepted_desc'),
          });
        }
      };

      window.addEventListener('stv-pwa-install', handler);
      window.addEventListener('stv-pwa-outcome', outcomeHandler);

      return () => {
        window.removeEventListener('stv-pwa-install', handler);
        window.removeEventListener('stv-pwa-outcome', outcomeHandler);
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
