const languages = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  pt: 'Português',
  de: 'Deutsch',
  it: 'Italiano',
  nl: 'Nederlands',
  tr: 'Türkçe',
  ar: 'ألعربية',
};

export default languages;
