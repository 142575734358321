import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'components/Link';
import { classNames } from 'lib/helpers';

// items: { name, description, href, icon } other props will be passed to the "Link" component
export default function NavPopover({ text, items, className = 'max-w-md' }) {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-gray-900' : 'text-gray-500',
              'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            )}
          >
            <span>{text}</span>
            <ChevronDownIcon
              className={classNames(
                open ? 'text-gray-600' : 'text-gray-400',
                isRTL ? 'mr-2' : 'ml-2',
                'h-5 w-5 group-hover:text-gray-500'
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className={classNames(
                'absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen sm:px-0',
                className
              )}
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  {items.map(
                    ({ name, href, description, icon: Icon, ...props }) => (
                      <Link
                        key={name}
                        href={href}
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        {...props}
                      >
                        {Icon && (
                          <Icon
                            className="flex-shrink-0 h-6 w-6 text-blue-600"
                            aria-hidden="true"
                          />
                        )}
                        <div className={Icon ? (isRTL ? 'mr-4' : 'ml-4') : ''}>
                          <p className="text-base font-medium text-gray-900">
                            {name}
                          </p>
                          {description && (
                            <p className="mt-1 text-sm text-gray-500">
                              {description}
                            </p>
                          )}
                        </div>
                      </Link>
                    )
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
