import 'styles/global.css';

import { trackEvent } from './src/lib/analytics';

export { wrapRootElement } from './wrapRootElement';

// Called when the Gatsby browser runtime first starts
export function onClientEntry() {
  // Source: https://developers.google.com/web/fundamentals/app-install-banners/
  window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault();
    // Emit an event to show the install buttons
    setTimeout(() => {
      window.dispatchEvent(new Event('stv-pwa-install'));
    }, 2000);

    // Listeners of "stv-pwa-install" event must emit a "stv-pwa-prompt" event
    window.addEventListener('stv-pwa-prompt', () => {
      event.prompt();

      event.userChoice.then((choiceResult) => {
        // Listeners can check the outcome using this event
        window.dispatchEvent(
          new CustomEvent('stv-pwa-outcome', {
            detail: { outcome: choiceResult.outcome },
          })
        );

        trackEvent({
          event: 'A2HS',
          category: 'a2hs-outcome',
          label: choiceResult.outcome,
        });
      });
    });
  });

  // Track PWA installs
  window.addEventListener('appinstalled', () => {
    trackEvent({ event: 'appinstalled' });
  });
}

// Display a custom message once the service worker finds an update
// Note: in development use "onClientEntry" with "setTimeout" instead of "onServiceWorkerUpdateReady" to test the outcome
export function onServiceWorkerUpdateReady() {
  // Emit an event to be handled by the app to display a message to the user
  window.dispatchEvent(new Event('stv-sw-update'));
}
