import { trackShare } from 'lib/analytics';

export function openNativeShareMenu({ title, text, url, fallback }) {
  // Use the web share API if available
  if (navigator.share) {
    navigator
      .share({ title, text, url })
      .then(() => trackShare('shareapi'))
      .catch((error) => console.log('Share API Error: ', error));
  } else {
    fallback?.();
  }
}
