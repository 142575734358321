import { useI18next } from 'gatsby-plugin-react-i18next';

import i18nLanguages from 'i18n/languages';

export default function useLangList() {
  const { language, languages, originalPath, ...rest } = useI18next();

  const langList = languages.map((language) => ({
    name: i18nLanguages[language],
    href: originalPath,
    // Prop for the "Link" component  of "gatsby-plugin-react-i18next"
    language,
  }));

  return {
    langList,
    langName: i18nLanguages[language],
    language,
    languages,
    originalPath,
    ...rest,
  };
}
