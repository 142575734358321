import React from 'react';
import { Location, globalHistory } from '@reach/router';
import { QueryParamProvider } from 'use-query-params';

export function wrapRootElement({ element }) {
  return (
    <Location>
      {({ location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          {element}
        </QueryParamProvider>
      )}
    </Location>
  );
}
