import { useStaticQuery, graphql } from 'gatsby';

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        siteUrl
        email
        copyright_email
        social {
          twitter
          facebook
        }
      }
    }
  }
`;

export default function useSiteMetadata() {
  const {
    site: { siteMetadata },
  } = useStaticQuery(QUERY);

  return siteMetadata;
}
