import React from 'react';
// import { SpeakerphoneIcon } from '@heroicons/react/outline';
// import { useTranslation } from 'gatsby-plugin-react-i18next';

import useAppInstall from 'hooks/useAppInstall';
import useServiceWorkerUpdate from 'hooks/useServiceWorkerUpdate';
import Header from 'components/Header';
import Footer from 'components/Footer';
// import FloatingBanner from 'components/FloatingBanner';
// import FloatingBox from 'components/FloatingBox';
// import Button from 'components/Button';
// import useLocalStorage from 'hooks/useLocalStorage';
// import { isBrowser, isStandalone } from 'lib/devices';
// import { trackPromotion } from 'lib/analytics';

// import screenshot from 'images/videoproc/screenshot1.png';

export default function Layout({ children }) {
  // const [showBanner, setShowBanner] = useLocalStorage(
  //   'vp_banner',
  //   true,
  //   // Show every 10 mins
  //   10 * 60 * 1000
  // );

  // const { t } = useTranslation();

  // Display an install notification for the PWA
  useAppInstall();

  // Notify the user when there's an update to reload the page
  useServiceWorkerUpdate();

  return (
    <>
      <Header />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{children}</main>

      <Footer />

      {/* {isBrowser && !isStandalone && (
        <FloatingBanner
          show={showBanner}
          onDismiss={() => setShowBanner(false)}
          icon={SpeakerphoneIcon}
          mobileText={t('videoproc:save_big')}
          text={t('videoproc:save_big_full')}
          button={
            <Button
              as="a"
              color="basic"
              href="https://www.videoproc.com/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-full-float&ttref=stvideo-full-float"
              target="_blank"
              rel="nofollow noreferrer noopener"
              className="w-full"
              onClick={() => trackPromotion('btn-float')}
            >
              {t('common:learn_more')}
            </Button>
          }
        />
      )} */}

      {/* {isBrowser && !isStandalone && (
        <FloatingBox
          show={showBanner}
          onDismiss={() => setShowBanner(false)}
          icon={SpeakerphoneIcon}
          title="Save Big on VideoProc"
          button={
            <Button color="basic" className="w-full">
              Learn More
            </Button>
          }
        >
          <img className="rounded-md" src={screenshot} alt="App screenshot" />
        </FloatingBox>
      )} */}
    </>
  );
}
