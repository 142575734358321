import React from 'react';

import ToastElement from './Toast';

const ToastContext = React.createContext();

// props: title, appearance, children, afterLeave, autoDismiss, autoDismissTimeout
function Toast({ onDismiss, ...props }) {
  const [show, setShow] = React.useState(true);

  return (
    <ToastElement
      appear={true}
      show={show}
      onDismiss={() => {
        setShow(false);
        onDismiss?.();
      }}
      {...props}
    />
  );
}

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const addToast = (options) => {
    const id = Math.random().toString(36).substr(2, 9);

    setToasts((toasts) => {
      const newToast = {
        id,
        ...options,
      };

      return [...toasts, newToast];
    });

    return id;
  };

  const removeToast = (id) => {
    setToasts((toasts) => {
      return toasts.filter((toast) => id !== toast.id);
    });
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}

      {toasts.map(({ id, content, ...props }) => (
        // The toasts will be created using ReactDOM.createPortal in the "toasts" element
        <Toast key={id} afterLeave={() => removeToast(id)} {...props}>
          {content}
        </Toast>
      ))}
    </ToastContext.Provider>
  );
}

export function useToasts() {
  const ctx = React.useContext(ToastContext);

  if (!ctx) {
    throw Error(
      'The `useToasts` hook must be called from a descendent of the `ToastProvider`.'
    );
  }

  return ctx;
}
