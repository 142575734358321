import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { isBrowser } from 'lib/devices';
import { useToasts } from 'components/Toast';
import Button from 'components/Button';

export default function useServiceWorkerUpdate() {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  React.useEffect(() => {
    if (isBrowser) {
      const handler = () => {
        addToast({
          title: t('common:pwa.updated_title'),
          content: (
            <>
              <p>{t('common:pwa.updated')}</p>
              <p>{t('common:pwa.updated_q')}</p>
              <Button className="mt-4" onClick={() => window.location.reload()}>
                {t('common:pwa.update')}
              </Button>
            </>
          ),
        });
      };

      window.addEventListener('stv-sw-update', handler);

      return () => {
        window.removeEventListener('stv-sw-update', handler);
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
