import React from 'react';

const baseStyle =
  'flex items-center justify-center rounded-md shadow-sm border focus:outline-none relative';

const buttonType = {
  primary:
    'bg-gray-800 hover:bg-gray-900 border-transparent focus:ring-gray-500 focus:ring-2 focus:ring-offset-2',
  secondary:
    'bg-blue-500 hover:bg-blue-600 border-transparent focus:ring-blue-500 focus:ring-2 focus:ring-offset-2',
  blue: 'bg-blue-600 hover:bg-blue-700 border-transparent focus:ring-blue-600 focus:ring-2 focus:ring-offset-2',
  basic: 'bg-white hover:bg-blue-50 border-transparent',
  success:
    'bg-green-500 hover:bg-green-600 border-transparent focus:ring-green-500 focus:ring-2 focus:ring-offset-2',
  warning:
    'bg-yellow-500 hover:bg-yellow-600 border-transparent focus:ring-yellow-500 focus:ring-2 focus:ring-offset-2',
  danger:
    'bg-red-500 hover:bg-red-600 border-transparent focus:ring-red-500 focus:ring-2 focus:ring-offset-2',
};

const textColor = {
  primary: 'text-white',
  secondary: 'text-white',
  blue: 'text-white',
  basic: 'text-blue-600',
  success: 'text-white',
  warning: 'text-white',
  danger: 'text-white',
};

const buttonSize = {
  xs: 'px-2.5 py-1.5 text-xs',
  sm: 'px-3 py-2 text-sm leading-4',
  base: 'px-4 py-2 text-sm',
  lg: 'px-4 py-2 text-base',
  xl: 'px-6 py-3 text-base',
  '2xl': 'px-8 md:px-10 py-3 md:py-4 text-base md:text-lg',
};

export default function Button({
  as: Element = 'button',
  color = 'primary',
  size = 'base',
  className = '',
  isLoading = false,
  children,
  ...props
}) {
  return (
    <Element
      className={`${baseStyle} ${buttonType[color]} ${buttonSize[size]} ${
        isLoading ? 'text-transparent pointer-events-none' : textColor[color]
      } ${className}`}
      {...props}
    >
      {isLoading && (
        <svg
          className="animate-spin h-5 w-5 text-white absolute"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {children}
    </Element>
  );
}
