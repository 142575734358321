import React from 'react';

import AppProviders from '../../src/components/AppProviders';
import Layout from '../../src/components/Layout';

export function wrapPageElement({ element, props }) {
  return (
    <AppProviders>
      <Layout {...props}>{element}</Layout>
    </AppProviders>
  );
}
