import React from 'react';

import { isBrowser } from 'lib/devices';
import { openNativeShareMenu } from 'lib/share';

import ShareModal from './ShareModal';

const ShareContext = React.createContext();

export function ShareProvider({ children }) {
  const [open, setOpen] = React.useState(false);

  const shareUrl = isBrowser ? window.location.href : '';
  const title = isBrowser ? window.document.title : '';

  const openShareMenu = () => {
    openNativeShareMenu({
      url: shareUrl,
      title,
      text: title,
      // Open the ShareModal only if we can't use the native share menu
      fallback: () => setOpen(true),
    });
  };

  return (
    <ShareContext.Provider value={{ openShareMenu }}>
      {children}

      <ShareModal
        size="2xl"
        open={open}
        setOpen={setOpen}
        shareUrl={shareUrl}
        title={title}
      />
    </ShareContext.Provider>
  );
}

export function useSocialSharing() {
  const ctx = React.useContext(ShareContext);

  if (!ctx) {
    throw Error(
      'The `useSocialSharing` hook must be called from a descendent of the `ShareProvider`.'
    );
  }

  return ctx;
}
