import React from 'react';
import ReactDOM from 'react-dom';
import { Transition } from '@headlessui/react';

import { isBrowser } from 'lib/devices';

import { Timer } from './utils';
import ICONS from './icons';

export default function Toast({
  title,
  children,
  appearance,
  onDismiss,
  autoDismiss = false,
  autoDismissTimeout = 5000,
  ...props
}) {
  const timer = React.useRef(null);

  React.useEffect(() => {
    if (autoDismiss) {
      timer.current = new Timer(onDismiss, autoDismissTimeout);

      // Clear the timeout on unmount
      return () => timer.current?.clear();
    }
  }, [autoDismiss, autoDismissTimeout, onDismiss]);

  return isBrowser
    ? ReactDOM.createPortal(
        <Transition
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          onMouseEnter={() => timer.current?.pause()}
          onMouseLeave={() => timer.current?.resume()}
          {...props}
        >
          {/* <!--
        Notification panel, show/hide based on alert state.

        Entering: "transform ease-out duration-300 transition"
          From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          To: "translate-y-0 opacity-100 sm:translate-x-0"
        Leaving: "transition ease-in duration-100"
          From: "opacity-100"
          To: "opacity-0"
      --> */}
          <div className="p-4">
            <div className="flex items-start">
              {appearance && (
                <div className="flex-shrink-0">{ICONS[appearance]}</div>
              )}

              <div className="ml-3 w-0 flex-1 pt-0.5">
                {title && (
                  <p className="text-sm font-medium text-gray-900">{title}</p>
                )}
                <div className="mt-1 text-sm text-gray-500">{children}</div>
              </div>

              {onDismiss && (
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={onDismiss}
                  >
                    <span className="sr-only">Close</span>
                    {/* <!-- Heroicon name: solid/x --> */}
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>
        </Transition>,
        document.getElementById('toasts')
      )
    : null;
}
