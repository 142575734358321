// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-converter-js": () => import("./../../../src/pages/converter.js" /* webpackChunkName: "component---src-pages-converter-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-downloader-js": () => import("./../../../src/pages/downloader.js" /* webpackChunkName: "component---src-pages-downloader-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-file-not-found-js": () => import("./../../../src/pages/file-not-found.js" /* webpackChunkName: "component---src-pages-file-not-found-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sites-js": () => import("./../../../src/pages/sites.js" /* webpackChunkName: "component---src-pages-sites-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-videoproc-js": () => import("./../../../src/pages/videoproc.js" /* webpackChunkName: "component---src-pages-videoproc-js" */)
}

