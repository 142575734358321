import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  DownloadIcon,
  RefreshIcon,
  // DesktopComputerIcon,
  GlobeAltIcon,
  MailIcon,
  MenuIcon,
  SupportIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'components/Link';
import Button from 'components/Button';
import NavPopover from 'components/NavPopover';
import { useSocialSharing } from 'components/Share';
import useLangList from 'hooks/useLangList';
// import useIsClient from 'hooks/useIsClient';
import { classNames } from 'lib/helpers';
// import { trackPromotion } from 'lib/analytics';
// import { getEditorLink, getAppLink } from 'lib/videoproc';

export default function Header() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const { langList, language, langName, changeLanguage } = useLangList();

  const { openShareMenu } = useSocialSharing();

  // const isClient = useIsClient();

  const items = [
    {
      name: t('common:downloader'),
      href: '/downloader',
      icon: DownloadIcon,
    },
    {
      name: t('common:converter'),
      href: '/converter',
      icon: RefreshIcon,
    },
    // {
    //   name: t('common:desktop_app'),
    //   href: '/videoproc',
    //   icon: DesktopComputerIcon,
    //   rel: 'nofollow',
    // },
  ];

  const moreMenu = [
    {
      name: t('common:faq'),
      description: t('common:faq_desc'),
      href: '/faq',
      icon: SupportIcon,
    },
    {
      name: t('common:supported_sites'),
      description: t('common:supported_sites_desc'),
      href: '/sites',
      icon: GlobeAltIcon,
    },
    {
      name: t('common:contact_us'),
      description: t('common:contact_us_desc'),
      href: '/contact',
      icon: MailIcon,
    },
  ];

  return (
    <Popover className="relative bg-white">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              className={classNames(
                isRTL ? 'md:space-x-reverse' : '',
                'flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10'
              )}
            >
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link href="/home" className="font-extrabold text-xl">
                  <span className="text-blue-500">Save</span>
                  <span className="text-gray-900">The</span>
                  <span className="text-blue-500">Video</span>
                  <span className="text-gray-900">.com</span>
                </Link>
              </div>

              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>

              <Popover.Group
                as="nav"
                className={classNames(
                  isRTL ? 'space-x-reverse' : '',
                  'hidden md:flex space-x-10'
                )}
              >
                <Link
                  href="/downloader"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  {t('common:downloader')}
                </Link>

                <Link
                  href="/converter"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  {t('common:converter')}
                </Link>
                {/* <a
                  href={getEditorLink(i18n.language, 'stvideo-nav')}
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                  className="relative text-base font-medium text-gray-500 hover:text-gray-900 hidden lg:block"
                  onClick={() => trackPromotion('txt-header-editor')}
                >
                  {t('videoproc:editor')}
                  <span className="flex absolute h-2 w-2 top-0 right-0 -mt-1 -mr-2">
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                  </span>
                </a> */}
                {/* <Link
                  key={`${isClient}-1`}
                  href={getAppLink(i18n.language)}
                  target="_blank"
                  rel="nofollow noreferrer noopener"
                  className="text-base font-medium text-gray-500 hover:text-gray-900 hidden lg:block"
                  onClick={() => trackPromotion('txt-header-app')}
                >
                  {t('common:desktop_app')}
                </Link> */}
                {/* <Link
                  href="/videoproc"
                  className="text-base font-medium text-gray-500 hover:text-gray-900 hidden lg:block"
                  rel="nofollow"
                >
                  {t('common:desktop_app')}
                </Link> */}

                <NavPopover text={t('more')} items={moreMenu} />
              </Popover.Group>

              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <NavPopover
                  text={langName}
                  items={langList}
                  className="max-w-xs"
                />

                <Button
                  color="secondary"
                  className={isRTL ? 'mr-8' : 'ml-8'}
                  onClick={openShareMenu}
                >
                  {t('share')}
                </Button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <Transition
            show={open}
            as={React.Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <Link
                        href="/home"
                        className="font-extrabold text-xl focus:outline-none"
                      >
                        <span className="text-blue-500">Save</span>
                        <span className="text-gray-900">The</span>
                        <span className="text-blue-500">Video</span>
                        <span className="text-gray-900">.com</span>
                      </Link>
                    </div>

                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>

                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      {items.map(({ name, icon: Icon, ...props }) => (
                        <Link
                          key={name}
                          className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          {...props}
                        >
                          <Icon
                            className="flex-shrink-0 h-6 w-6 text-blue-600"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-base font-medium text-gray-900">
                            {name}
                          </span>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>

                <div className="py-6 px-5 space-y-6">
                  <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                    {moreMenu.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>

                  <div>
                    <div>
                      <label htmlFor="lang" className="sr-only">
                        Select a language
                      </label>
                      <select
                        id="lang"
                        name="lang"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        value={language}
                        onChange={(e) => changeLanguage(e.target.value)}
                      >
                        {langList.map(({ name, language }) => (
                          <option key={language} value={language}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <Button
                      className="w-full mt-6"
                      color="secondary"
                      onClick={openShareMenu}
                    >
                      {t('share')}
                    </Button>
                    {/* <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?{' '}
                      <a
                        href="/todo"
                        className="text-blue-600 hover:text-blue-500"
                      >
                        Sign in
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
