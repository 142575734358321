export const isBrowser = typeof window !== 'undefined';

// Android devices
const ANDROID_RE = /android/i;

// Mobile device detection
// Source = https =//developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
const MOBILE_RE = /Mobi/i;

// Windows
const WIN_RE = /Windows/i;

// macOS
const MAC_RE = /Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh/i;

// iOS
const IOS_RE = /iPhone|iPad|iPod/i;

// Bot/Crawler
const BOT_RE = /bot|crawl(er|ing)|spider|baidu|yandex|alexa/i;

// Detect display-mode standalone (PWA installed)
export function checkIsStandalone() {
  if (isBrowser) {
    // display-mode check + Safari check
    return (
      (window.matchMedia &&
        window.matchMedia('(display-mode: standalone)').matches) ||
      !!window.navigator.standalone
    );
  }

  return false;
}

export function checkIsAndroid() {
  if (isBrowser) {
    return ANDROID_RE.test(navigator.userAgent);
  }

  return false;
}

export function checkIsMobile() {
  if (isBrowser) {
    return MOBILE_RE.test(navigator.userAgent);
  }

  return false;
}

export function checkIsWin() {
  if (isBrowser) {
    return (
      navigator.platform.includes('Win') || WIN_RE.test(navigator.userAgent)
    );
  }

  return false;
}

export function checkIsMac() {
  if (isBrowser) {
    return (
      navigator.platform.includes('Mac') || MAC_RE.test(navigator.userAgent)
    );
  }

  return false;
}

export function checkIsiOS() {
  if (isBrowser) {
    return IOS_RE.test(navigator.userAgent);
  }

  return false;
}

// Check if bot/Search crawler
export function checkIsBot() {
  if (isBrowser) {
    return BOT_RE.test(navigator.userAgent);
  }

  return false;
}

export const isStandalone = checkIsStandalone();

export const isAndroid = checkIsAndroid();

export const isMobile = checkIsMobile();

export const isWin = checkIsWin();

export const isMac = checkIsMac();

export const isiOS = checkIsiOS();

export const isBot = checkIsBot();

export const isDesktop = isWin || (isMac && !isiOS);
