import React from 'react';

import { ToastProvider } from 'components/Toast';
import { ShareProvider } from 'components/Share';

export default function AppProviders({ children }) {
  return (
    <ToastProvider>
      <ShareProvider>{children}</ShareProvider>
    </ToastProvider>
  );
}
