import { isBrowser } from 'lib/devices';

// Track an event using Gtag manager
export function trackEvent({
  event,
  category: event_category,
  label: event_label,
}) {
  if (isBrowser && window.gtag) {
    window.gtag('event', event, {
      event_category,
      event_label,
    });
  }
}

export function trackPromotion(label) {
  trackEvent({
    event: 'click-vp',
    category: 'promotion',
    label,
  });
}

export function trackShare(method) {
  if (isBrowser && window.gtag) {
    window.gtag('event', 'share', { method });
  }
}
