module.exports = [{
      plugin: require('../plugins/layout-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","fr","pt","de","it","nl","tr","ar"],"defaultLanguage":"en","generateDefaultLanguagePage":false,"siteUrl":"https://www.savethevideo.com","i18nextOptions":{"defaultNS":"common","fallbackLng":"en","lowerCaseLng":true,"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","include_favicon":true,"cache_busting_mode":"name","theme_color_in_head":true,"name":"SaveTheVideo.com","short_name":"SaveTheVideo","start_url":"/?source=pwa&utm_source=pwa&utm_medium=shortcut","background_color":"#ffffff","theme_color":"#3b82f6","display":"standalone","scope":"/","share_target":{"action":"/","method":"GET","enctype":"application/x-www-form-urlencoded","params":{"title":"title","text":"text","url":"url"}},"legacy":true,"crossOrigin":"anonymous","cacheDigest":"2f4c07a604135121803ebab75c8c4f82"},
    },{
      plugin: require('../plugins/workbox-inject-manifest-plugin/gatsby-browser.js'),
      options: {"plugins":[],"config":{"swSrc":"./src/sw.js","swDest":"sw.js","mode":"production","chunks":["app","polyfill"]},"additionalManifestEntries":["/manifest.webmanifest"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-P8BPDKVWT5","head":true,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
